import React from "react";

export default ({ data }) => {
  const { overview } = data;
  const { paragraphs, bullet_points } = overview;
  return (
    <div id="overview" className="section">
      <h3>About</h3>
      {paragraphs && paragraphs.length ? (
        <p className="paragraph">
          {paragraphs.map((s) => (
            <>
              {s}
              <br />
              <br />
            </>
          ))}
        </p>
      ) : null}
      <p className="paragraph">
        {bullet_points.map((bp) => (
          <>
            {bp}
            <br />
            <br />
          </>
        ))}
      </p>
    </div>
  );
};
