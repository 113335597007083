import React from "react";

export default () => (
  <>
    <div className="section">
      <h3>Definitions</h3>
      <p className="paragraph">
        * <b>Jade Owl AB / the company</b> - the organisation with number
        559362-7960, registered in Sweden.
      </p>
      <p className="paragraph">
        * <b>Jade Owl AB's products</b> - software built, marketed, sold,
        open-sourced or provided for free by Jade Owl AB.
      </p>
      <p className="paragraph">
        * <b>You</b> - the person purchasing and/or using Jade Owl AB programs.
        This extends to anything you apply Jade Owl AB's software to.
      </p>
    </div>
    <div className="section">
      <h3>Cookie policy</h3>
      <p className="paragraph">
        Jade Owl AB respects users' privacy, and so no cookies are set on this
        website. We do not track any user activity for any purposes, be they
        marketing or analytical.
      </p>
    </div>
    <div className="section">
      <h3>Terms & Conditions</h3>
      <p className="paragraph">
        Jade Owl AB provides its products as-is, and Jade Owl AB is not
        responsible for any loss of data, property, financial means or health,
        be that as a direct result of Jade Owl AB's products or as a side-effect
        of using them.
      </p>
      <p className="paragraph">
        By purchasing any of Jade Owl AB's products, or obtaining them through
        any other means, you agree that any negative effects or results are your
        own responsibility. We encourage Jade Owl AB's customers to use Jade Owl
        AB's products in moderation and with care, and the company (or any of
        its shareholders or employees) cannot be held liable for any negative
        outcomes.
      </p>
      <p className="paragraph">
        Furthermore, by obtaining or using any of Jade Owl AB's products, you
        agree that you will not make, sell, modify or in any way distribute
        illegal copies of said products.
      </p>
    </div>
  </>
);
