import React from "react";

import { isMobile } from "../../utils";

export default ({ data }) => {
  const { video } = data;
  return (
    <div className="section">
      <iframe
        width={isMobile() ? "350" : "600"}
        height="315"
        src={`https://www.youtube.com/embed/${video}`}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  );
};
