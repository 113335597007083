import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages/home";
import { Legal } from "./pages/legal";
import { Product } from "./pages/product";

const products = require("./pages/products.json");
const open_source_projects = require("./pages/open_source_projects.json");

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route>
            <Route path="/" element={<Home />} />
            <Route path="/legal" element={<Legal />} />
            {products.map((p, i) => (
              <Route
                path={"/" + p.name}
                element={<Product product={p} key={i} />}
              />
            ))}
            {open_source_projects.map((p, i) => (
              <Route
                path={"/" + p.name}
                element={<Product product={p} key={i} />}
              />
            ))}
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
