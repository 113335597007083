import React from "react";

export default ({ data }) => {
  const { sourceInformation } = data;
  const { link, text } = sourceInformation;
  return (
    <div id="buy" className="section">
      <h3>Code Repository</h3>
      <a href={link} target="_blank" className="buy-button">
        <b>{text}</b>
      </a>
    </div>
  );
};
