import React from "react";

import Label from "./Label";
const products = require("../products.json");

const labelBackgrounds = {
  automation: "#7bb597",
  blockchain: "#f3d400",
  marketing: "#e44e7b",
  entertainment: "#0e80ff",
  sports: "#cc0000",
};

export default () => {
  return (
    <div id="products" className="section">
      <h3>Products</h3>
      <div id="gallery">
        {products.map((p, i) => (
          <div
            key={i}
            className="product_container"
            style={{
              alignItems: "center",
              marginTop: i !== 0 ? "4em" : "",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <a href={"/" + p.name} style={{ fontSize: "1.5em" }}>
                <img
                  src={p.logo}
                  className="product_logo"
                  style={{ marginTop: 0, marginBottom: "0.5em" }}
                />
              </a>
              <br />{" "}
              {p.tags.map((t) => (
                <Label
                  key={t}
                  t={t}
                  style={{ backgroundColor: labelBackgrounds[t] }}
                />
              ))}
            </div>
            <div>
              <p className="paragraph">
                <h4 style={{ textAlign: "center", display: "inline" }}>
                  {p.displayName}
                </h4>
                {" - "}
                {p.description} <br />
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
