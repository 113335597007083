import React from "react";

export default ({ data }) => {
  const { purchaseInformation } = data;
  const { type, link, text } = purchaseInformation;
  return (
    <div id="buy" className="section">
      <h3>{type == "commercial" ? "Buy" : "Use"}</h3>
      <a href={link} target="_blank" className="buy-button">
        <b>{text}</b>
      </a>
    </div>
  );
};
