import React from "react";

import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";

const Legal = () => (
  <>
    <Header />
    <Content />
    <Footer />
  </>
);

export { Legal };
