import React from "react";

import Header from "./Header";
import About from "./About";
import Products from "./Products";
import OpenSourceProjects from "./OpenSourceProjects";
import Contact from "./Contact";
import Footer from "./Footer";

const Home = () => (
  <>
    <Header />
    <About />
    <Products />
    <OpenSourceProjects />
    <Contact />
    <Footer />
  </>
);

export { Home };
