import React from "react";

export default () => {
  return (
    <div id="buy" className="section">
      <p style={{ textAlign: "center" }}>
        <a
          href="https://www.jadeowl.org"
          style={{ marginRight: 0, color: "blue" }}
        >
          Jade Owl AB
        </a>{" "}
        // {new Date().getFullYear()}
      </p>
    </div>
  );
};
