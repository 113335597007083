import React from "react";

import { isMobile } from "../../utils";

export default ({ data }) => {
  const { screenshots, name } = data;
  const gallery_items = [];
  if (screenshots) {
    for (let i = 1; i <= screenshots; i++) {
      gallery_items.push(
        <a
          href={window.location.href + `/screenshot_${i}.png`}
          style={
            isMobile()
              ? { marginRight: 0 }
              : { marginRight: i % 2 > 0 ? "1.5em" : 0 }
          }
          target="_href"
          className="gallery_link"
        >
          <img
            src={`/${name}/screenshot_${i}.png`}
            className="gallery_screenshot"
          />
        </a>
      );
    }
  }
  return (
    <div className="section">
      <h3>Screenshots</h3>
      <div id="gallery">{gallery_items}</div>
    </div>
  );
};
