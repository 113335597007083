import React from "react";

export default () => {
  return (
    <div id="buy" className="section">
      <p style={{ textAlign: "center" }}>
        By using our products you agree to our {" "}
        <a
          href="https://www.jadeowl.org/legal"
          style={{ marginRight: 0, color: "blue" }}
        >
          terms & conditions
        </a>
        <br/>
        <br/>
        <a
          href="https://www.jadeowl.org"
          style={{ marginRight: 0, color: "blue" }}
        >
          Jade Owl AB
        </a>{" "}
        // {new Date().getFullYear()}
      </p>
    </div>
  );
};
