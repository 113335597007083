import React from "react";

export default () => {
  return (
    <div id="about" className="section">
      <h3>About</h3>
      <p className="paragraph">
        🦉 Jade Owl is a small software company in Sweden, releasing various
        products, ranging from reliable tools that solve niche problems to
        entertainment.
      </p>
    </div>
  );
};
