import React from "react";

export default ({ data }) => {
  const { howTo } = data;
  const { steps, paragraph, running } = howTo;
  return (
    <div id="how_to" className="section">
      {steps && paragraph ? (
        <>
          <h3>Seting up</h3>
          <p className="paragraph">{paragraph}</p>
          <br />
          <ul style={{ maxWidth: 600, textAlign: "left" }}>
            {steps.map((s) => (
              <>
                <li>{s}</li>
                <br />
              </>
            ))}
          </ul>
          <br />
          <br />
        </>
      ) : null}
      <h3>Running</h3>
      <ul style={{ maxWidth: 600, textAlign: "left" }}>
        {running.map((s) => (
          <li>{s}</li>
        ))}
      </ul>
    </div>
  );
};
