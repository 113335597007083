import React from "react";

export default ({ data }) => {
  const { logo, shortDescription } = data;
  return (
    <div id="nav_container">
      <img src={logo} style={{ maxWidth: 300 }} />
      <p id="nav_product_description">
        {shortDescription}
      </p>
      <div id="nav">
        <a href="#overview" className="nav_link">
          Overview
        </a>
        <a href="#how_to" className="nav_link">
          How-to
        </a>
        <a href="#gallery" className="nav_link">
          Gallery
        </a>
        <a href="#buy" className="nav_link">
          Buy
        </a>
      </div>
    </div>
  );
};
