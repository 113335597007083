import React from "react";

import Header from "./Header";
import Video from "./Video";
import Overview from "./Overview";
import Gallery from "./Gallery";
import Running from "./Running";
import Buy from "./Buy";
import Disclaimer from "./Disclaimer";
import Source from "./Source";

function Product({ product }) {
  const {
    name,
    shortDescription,
    logo,
    screenshots,
    video,
    overview,
    howTo,
    purchaseInformation,
    sourceInformation,
  } = product;
  return (
    <>
      {logo && <Header data={{ logo, shortDescription }} />}
      {video && <Video data={{ video }} />}
      {overview && <Overview data={{ overview }} />}
      {screenshots && <Gallery data={{ screenshots, name }} />}
      {howTo && <Running data={{ howTo }} />}
      {purchaseInformation && <Buy data={{ purchaseInformation }} />}
      {sourceInformation && <Source data={{ sourceInformation }} />}
      <Disclaimer />
    </>
  );
}

export { Product };
