import React from "react";

export default () => {
  return (
    <div id="contact" className="section">
      <h3>Contact</h3>
      <a href="mailto:contact@jadeowl.org">contact@jadeowl.org</a>
    </div>
  );
};
