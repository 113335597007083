import React from "react";

export default () => {
  return (
    <div id="nav_container">
      <img src={"jade_owl_logo.png"} style={{ maxWidth: 300 }} />
      <p style={{ fontSize: "1.5em" }}>
        a software house producing <br /> niche programs
      </p>
      <div id="nav">
        <a href="#about" className="nav_link">
          Overview
        </a>
        <a href="#products" className="nav_link">
          Products
        </a>
        <a href="#contact" className="nav_link">
          Contact
        </a>
      </div>
    </div>
  );
};
